export default {
    language: {
        name: 'German'
    },
    sidebar: {
        home: "Datenübersicht",
        endbenutzer: "EBVerw",
        plant: "Szenen",
        plant_plant: 'Kraftwerk',
        device: "Geräteverw",
        datalog: "Collector-Liste",
        inverter: "Wechselrichterliste",
        sotrage: "Energiespeicherliste",
        socket: "Socket-Liste",
        battery: "Batterieliste",
        agent: "Agent-Verwaltung",
        systematicSupply: "Versandprotokoll",
        firmware: "Firmware-Liste",
        firmware_up: "Firmware-Upload",
        firmware_log: "Upgrade-Datensatz",
        system: "System",
        system_datalog_log: "Collector-Protokoll",
        system_log: "Ops-Protokoll",
        system_tongxun: "Kom.-Protokoll",
        system_app: "App-Version",
        system_limits:'AuthM',
        system_menu:'Menüleiste',
        system_company:'Betriebsführung',

        personal: "Benutzerinformationen",
        logout: "Vom Konto abmelden"
    },
    login: {
        email: "E-Mail",
        password: "Passwort",
        remember: "E-Mail und Passwort merken",
        enteremail: "Bitte geben Sie Ihre E-Mail ein",
        enterpassword: "Bitte geben Sie Ihr Passwort ein",
        iot: "IoT-Sicherheitslücken offenbart",
        Cookies: "Unsere Website verwendet Cookies, die von uns und einigen Browsern bereitstellt werden. Cookies sind für den Betrieb der Website notwendig. Die weitere Nutzung bedeutet Ihre Zustimmung zur Verwendung von Cookies.",
        agree:"zustimmen",
        refuse:"Ablehnen",


    },
    home: {
        echarts1: {
            title: "Endbenutzerdatenübersicht",
            subhead: `Neue Benutzer heute/Gesamtzahl der Benutzer`,
            title: "Registrierte Benutzer"
        },
        echarts2: {
            title: "Endbenutzer-Online-Übersicht",
            subhead: "Heute angemeldete Benutzer/Gesamtzahl der Benutzer",
            title: "Angemeldete Benutzer"
        },
        echarts3: {
            title: 'Agenturdatenübersicht',
            subhead: "Heute angemeldete Agenturen/Gesamtzahl der Agenturen",
            title: "Agentur hinzufügen",
        },
        echarts4: {
            title: 'Szenendatenübersicht',
            subhead: 'Neue Szenen/Gesamtzahl der Szenen',
            title: 'Szene hinzufügen',
            title_plant: "Anlagenübersicht",
subhead_plant: "Neue Anlagen/Gesamtanzahl an Anlagen",
title_plant: "Anlage hinzufügen",
        },
        echarts5: {
            title: 'Datenloggerdatenübersicht',
            subhead: "Neue Datenlogger/Gesamtzahl der Datenlogger",
            title: "Datenlogger hinzufügen",
        },
        echarts6: {
            title: 'Gerätedatenübersicht',
            subhead: "Neue Geräte/Gesamtzahl der Geräte",
            title: "Gerät hinzufügen",
        },
    },
    End_user: {
        input_email: "Bitte geben Sie die Benutzer-E-Mail-Adresse ein",
        btn_search: "Suchen",
        btn_add: "Hinzufügen",
        btn_delete: "Löschen",
        btn_binding: "Binden",
        add_station: "Szenario hinzufügen",

        lable_StationName: "Szenenname",
        lable_StationType: "Szenentyp",
        lable_PVCapacity: "Photovoltaik-Kapazität",
        lable_InstalltionDate: "Installationsdatum",
        lable_Country: "Land",
        lable_City: "Stadt",
        lable_Address: "Adresse",
        lable_TimeZone: "Zeitzone",
        lable_Energyprice: "Energiepreis",
        lable_IncomeUnit: "Einkommenseinheit",

        input_StationName: "Bitte geben Sie den Szenennamen ein",
        input_StationType: "Bitte wählen Sie einen Typ",
        input_PVCapacity: "Bitte PV-Kapazität eingeben",
        input_InstalltionDate: "Bitte Datum wählen",
        input_Country: "Bitte wählen Sie ein Land",
        input_City: "Bitte wählen Sie eine Stadt",
        input_Address: "Bitte geben Sie die Adresse ein",
        input_TimeZone: "Bitte Zeitzone wählen",
        input_Energyprice: "Bitte Preis eingeben",
        input_IncomeUnit: "Wählen Sie die Einkommenseinheit",

        max_20: "Maximal 20 Zeichen",
        max_5: "Maximal 5 Zeichen",
        max_8: "Maximal 8 Zeichen",
        format: "Bitte geben Sie das richtige Format ein",

        close: "Schließen",
        submit: "Senden",

        title_delete: "Löschen?",
        no: "Nein",
        yes: "Ja",

        Bingding_Agent: "Agentur binden",
        Agent_Code: "Agentur:",
        please_select: "Bitte auswählen",
    },
    plant: {
        input_email: "Bitte geben Sie die Benutzer-E-Mail-Adresse ein",


        search: "Suchen",
        edit: "Bearbeiten",
        delete: "Löschen",

        edit_plant: "Szene bearbeiten",
        input_station: "Bitte geben Sie den Szenennamen ein",
        lable_StationName: "Szenenname",
        lable_StationType: "Szenentyp",

        lable_PVCapacity: "Photovoltaik-Kapazität",
        lable_InstalltionDate: "Installationsdatum",
        lable_Country: "Land",
        lable_City: "Stadt",
        lable_Address: "Adresse",
        lable_TimeZone: "Zeitzone",
        lable_Energyprice: "Energiepreis",
        lable_IncomeUnit: "Einkommenseinheit",

        input_StationName: "Szenenname",
        input_StationType: "Bitte wählen Sie einen Typ",
        input_PVCapacity: "Bitte PV-Kapazität eingeben",
        input_InstalltionDate: "Bitte Datum wählen",
        input_Country: "Bitte wählen Sie ein Land",
        input_City: "Bitte wählen Sie eine Stadt",
        input_Address: "Bitte geben Sie die Adresse ein",
        input_TimeZone: "Bitte Zeitzone wählen",
        input_Energyprice: "Bitte Preis eingeben",
        input_IncomeUnit: "Wählen Sie die Einkommenseinheit",

        max_20: "Maximal 20 Zeichen",
        max_5: "Maximal 5 Zeichen",
        max_8: "Maximal 8 Zeichen",
        format: "Bitte geben Sie das richtige Format ein",

        close: "Schließen",
        submit: "Senden",

        title_delete: "Löschen?",
        no: "Nein",
        yes: "Ja",

    },
    datalog: {
        email: "E-Mail-Adresse",
        datalogSn: "Datenlogger Seriennummer",
        search: "Suchen",
        Version: "Version",
        setting: "Einstellungen",
        upgrade: "Aktualisieren",
        delete: "Löschen",
        title_datalog: "Datenlogger-Einstellungen",

        span_info: "Informationen",
        span_datalog: "Datenlogger-Seriennummer:",
        span_name: "Alias:",
        span_time: "Aktualisierungszeit:",
        span_firmware: "Signal/Firmware-Version:",

        span_order: "Befehl",
        span_settingdns: "Domainnamen einstellen",
        span_inputsetting: "Bitte geben Sie den Domainnamen ein",
        span_upgrade: "Datenlogger aktualisieren",
        span_reset: "Datenlogger neu starten",

        span_setup: "Erweiterte Einstellungen",
        span_param: "Parameter",
        span_value: "Wert",

        btn_setup: "Erweiterte Einstellungen",
        btn_setting: "Einstellen",
        btn_settingdns: "Domainnamen einstellen",
        btn_upgrade: "Aktualisieren",
        btn_reset: "Neu starten",
        btn_read: "Lesen",
        btn_close: "Abbrechen",

        methods_version: "Version aktualisieren?",
        no: "Nein",
        yes: "Ja",

        methods_delete: "Löschen?",
        methods_rereset: "Neustarten?",
        methodes_inputDns: "Bitte geben Sie den Domainnamen ein",
        methodes_DNS: "Diesen Domainnamen einstellen?",
    },
    sotrage: {
        select: "Bitte auswählen",
        email: "Bitte E-Mail eingeben",
        datalogSn: "Datenlogger Seriennummer",
        deviceSn: "Geräteseriennummer",
        search: "Suchen",

        sotrage: "Energiespeichereinstellungen",
        Function_enable: "Funktionsaktivierung",
Model_number: "Modellnummer",
output_power: "Ausgangsleistung",
individual_address: "Individuelle Adresse",
Set_number: "Einstellungsnummer",
Set_time: "Einstellungszeit",
Set_Multiple_addresses:"Mehrere Adressen",


    },
    device: {
        select: "Bitte auswählen",
        email: "Bitte E-Mail eingeben",
        datalogSn: "Datenlogger Seriennummer",
        deviceSn: "Geräteseriennummer",
        search: "Suchen",

        title: "Wechselrichtereinstellungen",
        setting: "Einstellungen",
        setting_inverte: "Wechselrichterparameter einstellen:",
        read_data_device:"Lesen von Wechselrichterparametern:",

        input_start_address: "Startadressregister",
        input_ent_address: "Endadressregister",
        input_setting_value: "Bitte Einstellwert eingeben",

        read_data: "Registerdaten lesen:",
        input_start_read: "Startadressregister",
        input_end_read: "Endadressregister",

        btn_address: "Adresse setzen",
        btn_value: "Parameter setzen",
        btn_read: "Lesen",
        btn_close: "Zurück",

        selet_data: "Datum auswählen"
    },
    battery: {
        select: "Bitte auswählen",
        email: "Bitte gib deine E-Mail ein",
        datalogSn: "Bitte Datenlogger Seriennummer eingeben",
        deviceSn: "Bitte Seriennummer eingeben",
        search: "Suchen",

    },
    socket: {
        title: "Steckdosenkonfiguration",
        setting: "Einstellung",
        span: "Es wurden mehrere Registeradressen festgelegt, daher müssen auch mehrere Werte eingestellt werden. Die Werte müssen durch Kommas getrennt werden, zum Beispiel (1, 2, 3).",
        setting_device: "Inverter-Parameter einstellen:",
        startaddress: "Startregisteradresse",
        stopaddress: "Stopregisteradresse",
        value: "Bitte gib den Einstellwert ein",
        readdata: "Registerdaten lesen:",
        setting_address: "Adresse einstellen",
        setting_value: "Parameter einstellen",
        read: "Lesen",
        return: "Zurück",
    },
    agent: {
        close: "Schließen",
        submit: "Senden",
        input_email: "Bitte gib die Benutzer-E-Mail ein",
        search: "Suchen",
        add_agent: "Agent hinzufügen",
        import_device: "Gerät importieren",
        edit: "Bearbeiten",
        title: "Agent hinzufügen",
        email: "E-Mail",
        password: "Passwort",
        phone: "Telefonnummer",
        company: "Firma",
        language: "Sprache",
        address: "Adresse",
        input_email: "Bitte gib deine E-Mail ein",
        input_password: "Bitte gib dein Passwort ein",
        input_phone: "Bitte gib deine Telefonnummer ein",
        input_company: "Bitte wähle eine Firma aus",
        input_language: "Bitte gib deine Sprache ein",
        input_address: "Bitte gib deine Adresse ein",
        relus_email: "Bitte gib eine gültige E-Mail-Adresse ein",
        title_edit: "Agent bearbeiten",
        title_device: "Hochladen",
        span: "Beispiel: Excel-Tabelle",
        btn_upload: "Hochladen",
        delete: "Löschen",
        agent_delete: "Agent löschen?",
    },
    ship: {
        starttime: "Startzeit",
        endtime: "Endzeit",
        deviceSn: "Bitte gib die Datenlogger Seriennummer ein",
        select: "Bitte wähle eine Firma aus",
        search: "Suchen",

    },



    Firmware: {
        file_name: "Bitte gib den Dateinamen ein",
        search: "Suchen",
        add: "Hinzufügen",
        delete: "Löschen",
        title: "Firmware-Upload",
        select_folder: "Ordner auswählen:",
        select_folder_type: "Bitte wähle den Ordner-Typ aus",
        craete_folder: "Neuen Ordner erstellen",
        folder_path: "Ordnerpfad:",
        folder_name: "Dateiname:",
        input_folder_name: "Gib den Dateinamen ein",
        create: "Ordner erstellen",
        listed_files: "Dateiliste:",
        delete: "Löschen",

        import_folder: "Ordner importieren",
        uplad_folder: "Ordner hochladen",
        close: "Schließen",
    },



    Upgrade: {
        starttime: "Startzeit",
        endtime: "Endzeit",
        deviceSn: "Bitte gib die Datenlogger Seriennummer ein",
        type: "Bitte wähle einen Typ aus",
        search: "Suchen",
        datalog: "Datenlogger",
        Inverter: "Wechselrichter",
        Control: "Steuerung",

    },
    datalogs_logs: {
        starttime: "Startzeit",
        endtime: "Endzeit",
        deviceSn: "Bitte gib die Datenlogger Seriennummer ein",
        Version: "Bitte gib die Version ein",
        search: "Suchen",
    },
    Logs: {
        starttime: "Startzeit",
        endtime: "Endzeit",
        deviceSn: "Bitte geben Sie die Datenlogger Seriennummer ein",
        type: "Bitte wählen Sie den Typ aus",
        search: "Suchen",


    },
    Special: {
        starttime: "Startzeit",
        endtime: "Endzeit",
        deviceSn: "Bitte geben Sie die Datenlogger Seriennummer ein",
        datalogSn: "Bitte geben Sie die Datensammler-Seriennummer ein",
        type: "Bitte wählen Sie den Typ aus",
        search: "Suchen",
        save: "Speichern",
        Examples: "Beispiele: (2305210230, 2305210139)",
    },
    App: {
        System_Config: "Systemkonfiguration",
        APP_Version: "App-Version veröffentlichen",
        Android_Version: "Android-Version:",
        IOS_Version: "iOS-Version:",
        APP_Type: "App-Typ:",
        New_Version: "Neue Version",
        update_content: "Aktualisierungsinhalte (mit '/n' trennen):",
        Whether_update: "Update erzwingen:",
        no: "Nein",
        yes: "Ja",
        submit: "Senden",
        projectType:"Projekttyp",
        VersionType:"Versionstyp",
    },
    Tips: {
        app_submit: "Informationen ändern bestätigen?",
        firmware_delete: "Löschen bestätigen?",
        firmware_deleteCDN: "Dateien im CDN löschen?",
        yes: "Ja",
        no: "Nein",
    },

    global: {
        DataDetails: "Datendetails",
        device: "Wechselrichtereinstellungen",
        device_data: "Wechselrichterparam. einstellen",
        read_data:"Lesen von Wechselrichterparametern",
        storage: "Energiespeichereinstellungen",
        storage_data: "Energiespeicherparameter einstellen",
        storage_read:"Lesen von Energiespeicherparametern",

        battery: "Batterieeinstellungen",
        battery_data: "Batterieparameter einstellen",

        socket: "Steckdosen-Einstellungen",
        socket_data: "Steckdosenparameter einstellen",
        Read_Write:"Lesen/Schreiben von Daten",
        select:"Bitte auswählen",
        value:"Wert",
        password:"Passwort-Einstellungen",
        enter:"Passwort eingeben",
        number:"Seriennummer",
        enternumber:"Seriennummer eingeben oder auswählen zum Lesen",
        resetdatalog:"Sammler neu starten",

        reset:"Neu starten",

        read:"Lesen",
        setting:"Einstellung",
        read_battery_data:"Lesen Batteriedaten",

        bms_sanke_Li: {    
            time: "BMS Time",    
            serialNumber: "BMS Serial Number",    
            batterySerialNumber: "Battery Serial Number",    
            chargeDischargeLimits: "Charge and Discharge Limits",    
            shieldProtection: "Shield Protection",    
            thresholdCurrent: "Threshold Current",    
            cycleCount: "Cycle Count",    
            modifyProtocol: "Modify Protocol",    
            overallOvervoltageAlarm: "Overall Overvoltage Alarm",    
            overallOvervoltageProtection: "Overall Overvoltage Protection",    
            overallOvervoltageProtectionRecover: "Overall Overvoltage Protection Recovery",    
            overallOvervoltageProtectionDelay: "Overall Overvoltage Protection Delay",    
            singleCellOvervoltageAlarm: "Single Cell Overvoltage Alarm",    
            singleCellOvervoltageProtection: "Single Cell Overvoltage Protection",    
            singleCellOvervoltageProtectionRecover: "Single Cell Overvoltage Protection Recovery",    
            singleCellOvervoltageProtectionDelay: "Single Cell Overvoltage Protection Delay",    
            overallOverdischargeAlarm: "Overall Overdischarge Alarm",    
            overallOverdischargeProtection: "Overall Overdischarge Protection",    
            overallOverdischargeProtectionRecover: "Overall Overdischarge Protection Recovery",    
            overallOverdischargeProtectionDelay: "Overall Overdischarge Protection Delay",    
            singleCellOverdischargeAlarm: "Single Cell Overdischarge Alarm",    
            singleCellOverdischargeProtection: "Single Cell Overdischarge Protection",    
            singleCellOverdischargeProtectionRecover: "Single Cell Overdischarge Protection Recovery",    
            singleCellOverdischargeProtectionDelay: "Single Cell Overdischarge Protection Delay",    
            chargeOvercurrentAlarm: "Charge Overcurrent Alarm",    
            chargeOvercurrentProtection: "Charge Overcurrent Protection",    
            chargeOvercurrentProtectionDelay: "Charge Overcurrent Protection Delay",    
            chargeOvercurrentSecondProtection: "Charge Overcurrent Second Protection",    
            chargeOvercurrentSecondProtectionDelay: "Charge Overcurrent Second Protection Delay",    
            dischargeOvercurrentAlarm: "Discharge Overcurrent Alarm",    
            dischargeOvercurrentProtection: "Discharge Overcurrent Protection",    
            dischargeOvercurrentProtectionDelay: "Discharge Overcurrent Protection Delay",    
            dischargeOvercurrentSecondProtection: "Discharge Overcurrent Second Protection",    
            dischargeOvercurrentSecondProtectionDelay: "Discharge Overcurrent Second Protection Delay",    
            shortCircuitProtectionCurrent: "Short Circuit Protection Current",    
            shortCircuitProtectionDelay: "Short Circuit Protection Delay",    
            highTemperatureChargingAlarm: "High Temperature Charging Alarm",    
            highTemperatureChargingProtection: "High Temperature Charging Protection",    
            highTemperatureChargingProtectionRecover: "High Temperature Charging Protection Recovery",    
            highTemperatureDischargingAlarm: "High Temperature Discharging Alarm",    
            highTemperatureDischargingProtection: "High Temperature Discharging Protection",    
            highTemperatureDischargingProtectionRecover: "High Temperature Discharging Protection Recovery",    
            lowTemperatureChargingAlarm: "Low Temperature Charging Alarm",    
            lowTemperatureChargingProtection: "Low Temperature Charging Protection",    
            lowTemperatureChargingProtectionRecover: "Low Temperature Charging Protection Recovery",    
            lowTemperatureDischargingAlarm: "Low Temperature Discharging Alarm",    
            lowTemperatureDischargingProtection: "Low Temperature Discharging Protection",    
            lowTemperatureDischargingProtectionRecover: "Low Temperature Discharging Protection Recovery",    
            powerTubeHighTemperatureAlarm: "Power Tube High Temperature Alarm",    
            powerTubeHighTemperatureProtection: "Power Tube High Temperature Protection",    
            powerTubeHighTemperatureProtectionRecover: "Power Tube High Temperature Protection Recovery",    
            environmentHighTemperatureAlarm: "Environment High Temperature Alarm",    
            environmentHighTemperatureProtection: "Environment High Temperature Protection",    
            environmentHighTemperatureProtectionRecover: "Environment High Temperature Protection Recovery",    
            environmentLowTemperatureAlarm: "Environment Low Temperature Alarm",    
            environmentLowTemperatureProtection: "Environment Low Temperature Protection",    
            environmentLowTemperatureProtectionRecover: "Environment Low Temperature Protection Recovery",    
            balancingStartVoltage: "Balancing Start Voltage",    
            balancingStartVoltageDifference: "Balancing Start Voltage Difference",    
            fullChargeVoltage: "Full Charge Voltage",    
            fullChargeCurrent: "Full Charge Current",    
            singleCellLowVoltageSleep: "Single Cell Low Voltage Sleep Voltage",    
            singleCellLowVoltageSleepDelay: "Single Cell Low Voltage Sleep Delay",    
            standbySleepDelay: "Standby Sleep Delay",    
            lowBatteryAlarmValue: "Low Battery Alarm Value",    
            overchargeProtectionCapacityRatioRelease: "Overcharge Protection Capacity Ratio Release",    
            heatingFilmStartTemperature: "Heating Film Start Temperature",    
            heatingFilmStopTemperature: "Heating Film Stop Temperature"    
          },
          TouchSetting:"Direktes Durchschicken",

    },

    btn: {
        setting_address: "Einstellungsadresse",
        close: "Schließen",
        submit:"Einreichen",

        Setting_parameters: "Parameter einstellen",
        Reading: "Lesen",
        search: "Suchen",
        add: "Hinzufügen",
        import: "Importieren",
        download: "Beispiel herunterladen",
        edit: "Bearbeiten",
        login:"Anmelden",
        export:"Exportieren",
        agent:"Agent",
        menu:"Menü",
        limits:"Speichern der Zuordnung",
        AddZ:"Hauptmenü hinzufügen",

    },
    form:
{
addShip: "Gerät hinzufügen",
editShip: "Gerät bearbeiten",
import: "Geräteinformationen importieren",
shipSN: "Liefer-Seriennummer",
agent: "Vertreiber",
networkCall: "Netzwerk-Rückruf ausführen",
warrantyPeriod: "Garantiezeit",
shiptime: "Lieferzeit",
shiptype: "Lieferart",
classify: "Klassifizierung",
file: "Datei hier ablegen oder klicken, um sie hochzuladen",

edit_plant: "Szenario bearbeiten",
input_station: "Geben Sie den Szenarionamen ein",
lable_StationName: "Szenarioname",
lable_StationType: "Szenariotyp",
input_StationName: "Geben Sie den Szenarionamen ein",

edit_plant_plant: "Bearb. Kraftwerk",
input_station_plant: "Geben Sie Kraftwerksname ein",
lable_StationName_plant: "Kraftwerksname",
lable_StationType_plant: "Kraftwerkstyp",
input_StationName_plant: "Geben Sie Kraftwerksname ein"
},

placeholder:
{
PshipSN: "Liefer-Seriennummer eingeben",
Pagent: "Vertreiber-Code eingeben",
PnetworkCall: "Rückruf bestätigen",
PwarrantyPeriod: "Garantiezeit bestätigen",
Pshiptime: "Lieferzeit bestätigen",
Pshiptype: "Lieferart bestätigen",
Pshiptype2: "Lieferart bestätigen",

Pclassify: "Klassifizierung bestätigen"
},
limits:{
    AddRoles: "Rollen hinzufügen",
nametip: "Bitte Rollenname eingeben",
remark: "Bitte Bemerkungen eingeben",
EditRoles:"Rolle bearbeiten",
RolesAgent:"Agent, der an Rollen gebunden ist",
RoleName:"Rollenname:",
Remark:"Bemerkung:",
},
Menu:{
    AddC: "Menü hinzufügen",
    EditC:"Menü bearbeiten",

    MenuName: "Menüname",
    MenuNameTip: "Bitte Menüname eingeben",
    url: "URL",
    urlTip: "Bitte URL eingeben",
    MenuType: "Menütyp",
    MenuTypeTip: "Bitte Menütyp auswählen" ,
    Remark: "Bemerkung",
    RemarkTip: "Bitte Bemerkung eingeben",


} ,info:{
    Basic_Information: "Grundinformationen",
User_name: "Benutzername",
Company: "Firma",
Adress: "Adresse",
Phone: "Handynummer",
Change_Password: "Passwort ändern",
New_Password: "Neues Passwort",
Old_Password:"旧密码",

Confirm_Password: "Passwort bestätigen",
Update_Acknowledge: "Aktualisierung bestätigen",
tip1: "Bitte geben Sie Ihr Passwort ein",
tip2: "Bitte Ihr Passwort erneut eingeben",
tip3: "Die beiden Passwörter stimmen nicht überein!",
tip4: "Diese Operation ist unumkehrbar, bitte bestätigen Sie Ihre Informationen",
},
}