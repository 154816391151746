import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/LoginView.vue'

Vue.use(VueRouter)

const routes = [
  // login
  {
    path: '/',
    name: 'home', 
    component: HomeView
  },
  {  
    path: '/iot',  
    name: 'iot',  
    component: () => import( '../views/iot/iot.vue') 
   }  ,
  // index
  {
    path: '/home',
    name: '',
    component: () => import( '../views/HomeView.vue')
  },
 
  //设备
  {
    path: '/device',
    name: '设备列表',
    component: () => import( '../views/list/deviceView')
  },
  //储能
  {
    path: '/sotrage', 
    name: '储能列表',
    component: () => import( '../views/list/sotrageView')
  },
  //电池
  {
    path: '/battery',
    name: '设备列表',
    component: () => import( '../views/list/batteryView')
  },
   //电池
   {
    path: '/socket',
    name: '设备列表',
    component: () => import( '../views/list/socketView')
  },
  //采集器列表
  {
    path: '/terminal',
    name: '采集器列表',
    component: () => import( '../views/Monitoring/DatalogView.vue')
  },
   //电站列表
   {
    path: '/plan',
    name: '电站列表',
    component: () => import( '../views/Monitoring/PlanView.vue')
  },
  // 代理商列表
  {
    path: '/agent',
    name: '代理商列表',
    component: () => import( '../views/MemberHome/AgentView.vue')
  },
  {
    path: '/agentlay',
    name: '代理商列表',
    component: () => import( '../views/MemberHome/AgentViewlay.vue')
  },
  // 个人中心
  {
    path: '/information',
    name: '个人中心',
    component: () => import( '../views/InformationView.vue')
  },
  // 终端用户
  {
    path: '/enduser',
    name: '终端用户',
    component: () => import( '../views/MemberHome/EndUserView.vue')
  },
   // 终端用户
   {
    path: '/firmware',
    name: '固件列表',
    component: () => import( '../views/FirmwareView.vue')
  },
   // 出货日志
   {
    path: '/shipmentlog',
    name: '出货日志',
    component: () => import( '../views/MemberHome/ShipmentlogView.vue')
  },
  // 采集器日志
  {
    path: '/datalog_logs',
    name: '采集器日志',
    component: () => import( '../views/System/datalog_logsView.vue')
  },
  {
    path: '/Upgraderecord',
    name: '升级记录',
    component: () => import( '../views/Upgraderecord.vue')
  },
  {
    path: '/Apprelease',
    name: '升级记录',
    component: () => import( '../views/System/AppreleaseView.vue')
  },
   // 终端用户
   {
    path: '/log',
    name: '操作日志',
    component: () => import( '../views/System/logView.vue')
  },
  //设置
  {
    path: '/specialsetting',
    name: '',
    component: () => import( '../views/System/Specialsetting.vue')
  },
  //权限
  {
    path: '/limits',
    name: '',
    component: () => import( '../views/Limits/limits.vue')
  },
  //菜单
  {
    path: '/menu',
    name: '',
    component: () => import( '../views/Limits/menu.vue')
  },
  {
    path: '/company',
    name: '',
    component: () => import( '../views/company/companyView.vue')
  },
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
