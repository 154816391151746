import Vue from 'vue'

import App from './App.vue'
import router from './router'
import request from "./utils/request";
import * as echarts from 'echarts'

import store from './store'
import ElementUI from 'element-ui'
import md5 from 'md5'
import 'element-ui/lib/theme-chalk/index.css'
// element-ui-table-插件，内容列宽自适应
import AFTableColumn from 'af-table-column'
//CSS 
import "@/assets//fonts/css.css";
//设置cookies过期时间为
// Cookies.set('myCookie', 'cookie value', { expires: 0.5/48 }) 
import adaptive from './assets/el-table/'
import './assets/icon/iconfont.css'
import i18n from './Language/index'

import Vue2OrgTree from 'vue2-org-tree'
Vue.use(Vue2OrgTree)
Vue.use(AFTableColumn)
Vue.config.productionTip = false 
Vue.prototype.$request=request
Vue.prototype.$md5 = md5
Vue.prototype.$echarts =echarts
Vue.use(adaptive)

Vue.use(ElementUI,{
  fallbackLocale: 'en-US', //如果没有找到要显示的语言，则默认显示 ‘en’
  i18n: (key, value) => i18n.t(key, value)
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
