<template>
    <div class="logo_box">
      <img v-if="dns=='server-hr.shuoxd.com'" src="../assets/img/NaHui.png" style="max-width: 70%; width: 15%; margin-left: 20px; min-width: 10%; margin-right: 10px;">
      <img  v-else src="../assets/img/logo.png" style="max-width: 70%; width: 15%; margin-left: 20px; min-width: 10%; margin-right: 10px;">

      <span v-if="dns=='server-hr.shuoxd.com'" class="title" style="color: aliceblue; font-size: 14px;"> Nahui Intelligent </span>
      <span v-else class="title" style="color: aliceblue; font-size: 18px;"> AECC  </span>
    </div>
  </template>
  
  <script>

  export default {
    name: 'LogoBox',
    // 组件的逻辑代码
    props: {
    dns: {
      type: String,
      default: () => ''
    },
  },
  data() {
    return {
    
    };
  },
  }
  </script>
  
  <style lang="scss" scoped>
   .logo_box {
                width: 200px;
                height: 6vh;
                background-color: #113E68;
                // border-right: solid 2px #1b3fd3;
                display: flex;
                align-items: center;

            }
  </style>