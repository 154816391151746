import axios from 'axios';
import router from '@/router'

//创建axios实例
const service = axios.create({
    baseURL: "api",
    changeOrigin: true,

    timeout: 20000,
    withCredentials: true,

    headers: {
        'Access-Control-Allow-Origin': '*',
        'strict-origin-when-cross-origin': '*',
        'Cache-Control': 'no-cache',
        //  'Content-Type': 'application/json; charset=utf-8',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept-Language':localStorage.getItem('Accept-Language'),
    }
})



//request   请求拦截器
service.interceptors.request.use(request => {

    return Promise.resolve(request)

}, err => {
    console.log('Request Error:', err);
    return Promise.reject(err)
})








//response  响应拦截器
service.interceptors.response.use(response => {
    // console.log('Response=>',response);
    if (response.data.result == 10000) {
        // 登录失败，跳转到首页，重新登录
        router.push('/')
        return Promise.reject(response)
    } else {
        return Promise.resolve(response)
    }
}, err => {
    return Promise.reject(err)
})

export default service;